<style lang="less" scoped>
@import './index.less';
</style>
<template>
  <suy-table :reload="loadData" v-model:columns="columns" >
    <template #search>
      <a-form ref="formQueryRef" layout="inline" :model="searchForm">
        <a-form-item label="">
          <a-input v-model:value="searchForm.name" placeholder="角色名称"/>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" @click="searchData">
            <template #icon>
              <SearchOutlined />
            </template>查询
          </a-button>
        </a-form-item>
      </a-form>
    </template>
    <template #toolbarRight>
      <a-button type="primary" @click="add">
        <template #icon>
          <PlusCircleOutlined />
        </template>
        新增
      </a-button>
    </template>
    <a-table :columns="[sortColumn,...columns]" :row-key="record => record.id" :data-source="listData" :pagination="pagination" :loading="loading" @change="handleTableChange">
      <template #sort="{ index }">
        {{index+1}}
      </template>

      <template #operation="{ record }">
        <span>
          <a @click="grant(record,2)">APP授权</a>
          <a-divider type="vertical" />
          <a @click="grant(record,1)">PC授权</a>
          <a-divider type="vertical" />
          <a @click="edit(record)">修改</a>
          <a-divider type="vertical" />
          <a-popconfirm v-if="listData.length" title="确定删除?" @confirm="deleteItem(record.id)">
            <a>删除</a>
          </a-popconfirm>
        </span>
      </template>
    </a-table>
  </suy-table>

  <a-modal v-model:visible="modalVisible" :title="modalTitle" :maskClosable="false" :confirm-loading="confirmLoading" @ok="save">
    <a-form ref="formRef" :model="form" layout="vertical">
      <a-form-item label="角色名称">
        <a-input v-model:value="form.name" />
      </a-form-item>
      <a-form-item label="备注">
        <a-textarea v-model:value="form.remark" showCount :maxlength="100" />
      </a-form-item>
    </a-form>
  </a-modal>
  <resourceTree ref="resourceTree" :terminalType="terminalType"/>
</template>
<script>

import { page, add as addItem, edit as editItem } from '@/api/system/role'
import { toRefs, reactive, onMounted, ref } from 'vue'
import { PlusCircleOutlined, SearchOutlined } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
import SuyTable from '@/components/SuyTable'
import resourceTree from './resourceTree'

export default {
  name: 'role',

  setup () {
    const resourceTree = ref(null)
    const state = reactive({
      loading: false,
      pagination: {
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      searchForm: {},
      listData: [],
      sortColumn: { title: '序号', slots: { customRender: 'sort' } },
      columns: [
        {
          title: '名称',
          dataIndex: 'name',
          key: 'name'
        },
        {
          title: '备注',
          dataIndex: 'remark',
          key: 'remark'
        },
        {
          title: '操作',
          key: 'operation',
          width: 300,
          slots: { customRender: 'operation' }
        }
      ],
      modalVisible: false,
      modalTitle: '',
      confirmLoading: false,
      form: {},
      rules: {
        name: [{ required: true, message: '名称不能为空', trigger: 'blur' }]
      }
    })

    const loadData = async () => {
      state.loading = true
      const { data, data: { records } } = await page({
        ...state.searchForm,
        current: state.pagination.current,
        size: state.pagination.size
      })
      state.listData = records
      state.pagination.current = data.current
      state.pagination.pageSize = data.size
      state.pagination.total = data.total
      state.loading = false
    }

    onMounted(loadData)

    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }

    const searchData = () => {
      state.pagination.current = 1
      loadData()
    }

    const add = () => {
      state.form = {
        name: '',
        remark: ''
      }
      state.modalVisible = true
      state.modalTitle = '新增角色'
    }

    const edit = item => {
      state.form = { ...item }
      state.modalVisible = true
      state.modalTitle = '修改角色'
    }

    const grant = (recode,num) => {
      resourceTree.value.onClose(true)
      resourceTree.value.loadData(recode.id,num)
    }

    const save = () => {
      state.confirmLoading = true
      if (state.form.id) {
        editItem({ role: state.form }).then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            state.modalVisible = false
            loadData()
          }
          state.confirmLoading = false
        }).catch(err => {
          console.log(err)
        })
      } else {
        addItem({ role: state.form }).then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            state.modalVisible = false
            loadData()
          }
          state.confirmLoading = false
        }).catch(err => {
          console.log(err)
        })
      }
    }

    const deleteItem = (id) => {

    }

    return {
      ...toRefs(state),
      searchData,
      resourceTree,
      handleTableChange,
      add,
      edit,
      grant,
      save,
      deleteItem
    }
  },
  components: {
    PlusCircleOutlined,
    SearchOutlined,
    SuyTable,
    resourceTree
  }
}
</script>
